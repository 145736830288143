@font-face {
  font-family: "MarkOT-Ultra";
  font-style: normal;
  font-weight: 400;
  src: local("MarkOT-Ultra"), url(./fonts/MarkOT-Ultra.otf) format("opentype");
}

html,
body {
  min-height: 100vh;
  font-family: Helvetica, Arial, sans-serif;
}

input:focus {
  outline: none;
}

.whiteSpace {
  white-space: pre;
}

.ltr,
.ltr * {
  unicode-bidi: embed !important;
  direction: initial !important;
}

.left {
  text-align: left;
}

/* Flamingo form field ovverrides to disable height resize when displaying error messages  */
.f-FormField {
  position: relative;
  padding: 0 1rem;
  margin-bottom: 1.6rem;
}

.f-Helper {
  position: absolute;
}

.f-FormEl-wrapper {
  margin-bottom: 8px !important;
  padding-top: 1rem;
}

input::placeholder {
  color: #8b99a2 !important;
  font-weight: 900;
  font-family: Arial, sans-serif;
}
