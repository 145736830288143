button.AppleButton {
  padding: 0;
  overflow: hidden;
  height: 40px;
  justify-content: center;
}
#appleid-signin {
  width: 100%;
  height: 100%;
}
#appleid-signin > div {
  max-width: 100vw;
  margin: 0 auto;
}
#appleid-signin svg {
  border-radius: 50%;
}
button.AppleButton,
button.AppleButton:hover {
  background: black;
}
