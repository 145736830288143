.PasswordField {
  position: relative;
}

.PasswordField .f-Button {
  position: absolute;
  right: 22px;
  top: 10px;
  height: 26px;
  width: 26px;
  margin: 0 !important;
  padding-right: 16px;
  background-color: white !important;
}

.PasswordField .f-FormEl-wrapper .f-Icon {
  display: none;
}
