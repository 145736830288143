.VerificationCode {
  display: flex;
  justify-content: center;
  padding-bottom: var(--f-space--m);
}

.styles_react-code-input__CRulA > input {
  border: none !important;
  margin-top: 20px !important;
  color: black !important;
  font-size: 36px !important;
  font-family: "MarkOT-Ultra" !important;
}

.styles_react-code-input__CRulA > input::placeholder {
  font-family: "MarkOT-Ultra" !important;
}

.VerificationCode .is-invalid .f-Helper {
  text-align: center !important;
}

.VerificationCode .ReactInputVerificationCode__item {
  color: #8b99a2 !important;
}

.styles_react-code-input__CRulA > input:focus,
.styles_react-code-input__CRulA > input:focus-visible {
  outline: none !important;
  border: none !important;
  caret-color: black !important;
}
